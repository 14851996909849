import moment from 'moment'
import { DATE_FORMAT_SAVE, DATE_FORMAT_VIEW, DATE_TIME_FORMAT_VIEW } from '../constants'
import 'moment-duration-format'

export const dateFormat = (value, format = DATE_FORMAT_VIEW) => {
	return moment(value).format(format)
}

export const datetimeFormat = (value, format = DATE_TIME_FORMAT_VIEW) => {
	return moment(value).format(format)
}

export const toDate = (value, format = DATE_FORMAT_SAVE) => {
	return moment(value, format).toDate()
}

export function calculateDuration(startTime, endTime) {
	if (!!startTime && !!endTime) {
		const startMoment = moment(startTime)
		const endMoment = moment(endTime)
		const duration = moment.duration(endMoment.diff(startMoment))
		let formattedDuration = ''

		const hours = Math.floor(duration.asHours())
		const minutes = Math.floor(duration.asMinutes()) % 60

		if (hours > 0) {
			formattedDuration += `${hours} hour`
			if (hours > 1) {
				formattedDuration += 's'
			}
		}

		if (minutes > 0) {
			if (formattedDuration.length > 0) {
				formattedDuration += ' '
			}
			formattedDuration += `${minutes} minute`
			if (minutes > 1) {
				formattedDuration += 's'
			}
		}

		if (formattedDuration.length === 0) {
			formattedDuration = '0 seconds'
		}

		return formattedDuration
	} else {
		return 'Invalid time'
	}
}

export function convertToFormattedString(startTime) {
	if (!!startTime) {
		const startMoment = moment(startTime)

		const startDateString = startMoment.format('dddd MMM D, YYYY')
		const startTimeString = startMoment.format('h:mmA')

		return `${startDateString} ${startTimeString}`
	} else {
		return 'Invalid time'
	}
}

export function convertToFormatteDate(startDate) {
	if (!!startDate) {
		const startMoment = moment(startDate)

		return {
			day: startMoment.format('dddd'),
			month: startMoment.format('MMM'),
			numberDay: startMoment.format('D'),
			year: startMoment.format('YYYY'),
		}
	} else {
		return 'Invalid time'
	}
}

export function replaceDateInTimestamp(originalDate, newDate) {
	// Create a new Date object from the original date string
	if (!!originalDate && !!newDate) {
		const dateObj = new Date(originalDate.toString())

		// Create a new Date object from the desired date
		const desiredDate = new Date(newDate)

		// Update the year, month, and day of the date object
		dateObj.setFullYear(desiredDate.getFullYear())
		dateObj.setMonth(desiredDate.getMonth())
		dateObj.setDate(desiredDate.getDate())
		return dateObj
	}
}

export function calculateInterviewDuration(interviewStartTime, interviewEndTime) {
	const startTime = moment(interviewStartTime)
	const endTime = moment(interviewEndTime)

	return endTime.isBefore(startTime)
}

export const convertToISOString = (date) => {
	return moment(date).toISOString()
}
export const convertToHoursAndMinutes = (workedHours) => {
	if (!workedHours) {
		return
	}
	const duration = moment.duration(workedHours, 'hours')
	const hours = Math.floor(duration.asHours())
	const minutes = Math.round(duration.asMinutes() % 60)
		.toString()
		.padStart(2, '0')
	return `${hours}:${minutes}`
}

export function convertTimeFormat(timeString) {
	if (!timeString) {
		return
	}
	const [hours, minutes] = timeString.split(':').map(Number)

	let result = ''
	if (hours > 0) {
		result += `${hours} hour${hours > 1 ? 's' : ''}`
	}
	if (minutes > 0) {
		result += ` ${minutes} min${minutes > 1 ? 's' : ''}`
	}

	return result.trim()
}

export function calculateDurationAndFormatSchedule(startTime, endTime) {
	if (!startTime || !endTime) {
		return
	}
	const start = moment(startTime)
	const end = moment(endTime)

	const duration = moment.duration(end.diff(start))

	// Calculate the total minutes
	const totalMinutes = duration.asMinutes()

	// Calculate the hours and minutes
	const hours = Math.floor(totalMinutes / 60)
	const minutes = Math.floor(totalMinutes % 60)

	// Return the duration in the desired format
	return `${hours}:${minutes.toString().padStart(2, '0')}`
}
export function calculatePercentTime(time, totalDuration) {
	if (!time || !totalDuration) {
		return null
	}
	// Parse the time and total duration strings
	const [hours, minutes] = time.split(':').map(Number)
	const [totalHours, totalMinutes] = totalDuration.split(':').map(Number)

	// Calculate the total minutes for the time and total duration
	const timeMinutes = hours * 60 + minutes
	const totalDurationMinutes = totalHours * 60 + totalMinutes

	// Calculate the percentage
	const percent = (timeMinutes / totalDurationMinutes) * 100

	return percent
}
export function formatTimestamp(timestamp) {
	if (!timestamp) {
		return
	}
	const parsedTimestamp = moment(timestamp)

	const formattedTime = parsedTimestamp.format('HH:mm')
	const formattedDate = parsedTimestamp.format('MMM D, YYYY')

	return `${formattedTime} - ${formattedDate}`
}

export const checkTimeFormat = (value, format = DATE_FORMAT_VIEW) => moment(value, format).isValid()
