export const WS_ACTION_NAMES = {
	SEND_MESSAGE: 'message',
	NEW_MESSAGE: 'new_message',
	REFRESH_UNREAD: 'REFRESH_UNREAD',
	STOP_LISTEN: 'STOP_LISTEN',
	RESTART_LISTEN: 'RESTART_LISTEN',
	SEND_PENDING_MESSAGE: 'SEND_PENDING_MESSAGE',
	ONLINE: 'online',
	OFFLINE: 'offline',
}

export const WS_MESSAGE_STATUS = {
	RECEIVED_MESSAGE: 'received',
	DELIVERED_MESSAGE: 'deliveried',
	FAILED: 'failed',
}
