export const COMMON_EVENT_CLICK_TRACKING = {
	notifBellBtn: 'notif-bell_btn',
	profileBtn: 'profile_btn',
	newsFeedBtn: 'news-feed_btn',
	resourcesBtn: 'resources_btn',
	applyBtn: 'apply_btn',
	updateNApplyBtn: 'update-n-apply_btn',
	settingsBtn: 'settings_btn',
	changePasswordBtn: 'change-password_btn',
	privacyPolicyBtn: 'privacy-policy_btn',
	signOutBtn: 'sign-out_btn',
	inviteFriendBtn: 'invite-friend_btn',
	givesBackBtn: 'gives-back_btn',
	addBtn: 'add_btn',
	programmesBtn: 'programmes_btn',
	completeProfileBtn: 'complete-profile_btn',
	extendAssignmentBtn: 'extend-assignment_btn',
	updateCredentialsBtn: 'update-credentials_btn',
	contactSupportBtn: 'contact-support_btn',
	contactRecruiterBtn: 'contact-recruiter_btn',
	homeBtn: 'home_btn',
	searchBtn: 'search_btn',
	myJobsBtn: 'my-jobs_btn',
	messagesBtn: 'messages_btn',
	welcomeBtn: 'welcome_btn',
	menuBtn: 'menu_btn',
	hotJobsTooltipBtn: 'hot-jobs-tooltip_btn',
	hotJobsViewAllLink: 'hot-jobs-view-all_link',
	hotJobsFaveBtn: 'hot-jobs-fave_btn',
	hotJobsShareBtn: 'hot-jobs-share_btn',
	hotJobsApplyBtn: 'hot-jobs-apply_btn',
	popularCitiesViewAllLink: 'popular-cities-view-all_link',
	popularCitiesXAvailJobsLink: 'popular-cities-x-avail-jobs_link',
	connectWithUsTwitterXBtn: 'connect-with-us-twitter-x_btn',
	connectWithUsFacebookBtn: 'connect-with-us-facebook_btn',
	connectWithUsLinkedinBtn: 'connect-with-us-linkedin_btn',
	connectWithUsInstagramBtn: 'connect-with-us-instagram_btn',
	connectWithUsTiktokBtn: 'connect-with-us-tiktok_btn',
	connectWithUsYoutubeBtn: 'connect-with-us-youtube_btn',
	backBtn: 'back_btn',
	xAvailJobsLink: 'x-avail-jobs_link',
	signInBtn: 'sign-in_btn',
	signUpBtn: 'sign-up_btn',
	jobSeekerDropdown: 'job-seeker_dropdown',
	disciplineSpecialtyDropdown: 'discipline-specialty_dropdown',
	locationDropdown: 'location_dropdown',
	radiusSlider: 'radius_slider',
	resetLink: 'reset_link',
	settingsCheckbox: 'settings_checkbox',
	workTypeCheckbox: 'work-type_checkbox',
	employmentTypeDropdown: 'employment-type_dropdown',
	shiftsDropdown: 'shifts_dropdown',
	minWageSlider: 'min-wage_slider',
	showHotJobsOnlyToggle: 'show-hot-jobs-only_toggle',
	sortBtn: 'sort_btn',
	faveBtn: 'fave_btn',
	shareBtn: 'share_btn',
	quickApplyBtn: 'quick-apply_btn',
	viewMoreBtn: 'view-more_btn',
	cancelBtn: 'cancel_btn',
	saveThisSearchLink: 'save-this-search_link',
	viewMapLink: 'view-map_link',
	copyLink: 'copy_link',
	applyJobNowBtn: 'apply-job-now_btn',
	closeBtnApplyForThisJob: 'close_btn_apply-for-this-job',
	confirmToApplyBtnApplyForThisJob: 'confirm-to-apply_btn_apply',
	cancelBtnApplyForThisJob: 'cancel_btn_apply',
	signInBtnApplyForThisJob: 'sign-in_btn_apply',
	skipNApplyLink: 'skip-n-apply_link',
	uploadResumeBtn: 'upload-resume_btn',
	nextStepBtn: 'next-step_btn',
	completeBtn: 'complete_btn',
	confirmNApplyBtn: 'confirm-n-apply_btn',
	closeBtnApplicationSentToRecruiter: 'close_btn_application-sent-to-recruiter',
	iGotItBtnApplicationSentToRecruiter: 'i-got-it_btn_application-sent-to-recruiter',
	welcomeToGHR: 'welcome-to-ghr-live_btn',
	copyLinkBtn: 'copy-link_btn',
	redditBtn: 'reddit_btn',
	twitterXBtn: 'twitter-x_btn',
	facebookBtn: 'facebook_btn',
	linkedinBtn: 'linkedin_btn',
	closeBtn: 'close_btn',
	upDateBtn: 'update_btn',
	confirmBtn: 'confirm_btn',
	addProfessionBtn: 'add-profession_btn',
	addSpecialtyBtn: 'add-specialty_btn',
	updateAndApplyBtn: 'update-n-apply_btn',
	verifyBtn: 'verify_btn',
	mySavedSearchesLink: 'my-saved-searches_link',
	minMatchingScoreSlider: 'min-matching-score_slider',
	alertTurnedOnToggle: 'alert-turned-on_toggle',
	alertPausedToggle: 'alert-paused_toggle',
	editBtn: 'edit_btn',
	removeBtn: 'remove_btn',
	deleteBtn: 'delete_btn',
	alertFreqBtn: 'alert-freq_btn',
	getNotifViaCheckbox: 'get-notif-via_checkbox',
	workTypeDropdown: 'work-type_dropdown',
	settingsDropdown: 'settings_dropdown',
	saveBtn: 'save_btn',
	prevPageBtn: 'prev-page_btn',
	nextPageBtn: 'next-page_btn',
	pageXbtn: 'page-x_btn',
	activeTabBtn: 'active-tab_btn',
	appliedTabBtn: 'applied-tab_btn',
	favoritesTabBtn: 'favorites-tab_btn',
	viewJobDetailsBtn: 'view-job-details_btn',
	filtersBtn: 'filters_btn',
	allStatusBtn: 'all-status_btn',
	openStatusBtn: 'open-status_btn',
	closedStatusBtn: 'closed-status_btn',
	doneBtn: 'done_btn',
	completeRequestedCredentialsBtn: 'complete-requested-credentials_btn',
	completePostOfferQuestionnarieBtn: 'complete-post-offer-questionnarie_btn',
	copyBtn: 'copy_btn',
	requestExtensionBtn: 'request-extension_btn',
	rateJobBtn: 'rate-job_btn',
	checkInBtn: 'check-in_btn',
	viewWorkTimeDetailsBtn: 'view-work-time-details_btn',
	pauseBtn: 'pause_btn',
	checkOutBtn: 'check-out_btn',
	resumeBtn: 'resume_btn',
	reviewRatingBtn: 'review-rating_btn',
	iGotItBtn: 'i-got-it_btn',
}
