export const getQueriesUrlParams = () => {
	const urlSearchParams = new URLSearchParams(window.location.search)
	return Object.fromEntries(urlSearchParams.entries()) || {}
}

export const formatStringUrl = (input) => {
	return input.replace(/[./\s-]+/g, '-').toLowerCase()
}

export function parsePathname(pathname) {
	return pathname.split('/').filter((item) => item !== '')
}
export function removeJobsPath(url) {
	return url
}
export const urlConstructor = (urlMapString, params) => {
	try {
		let url = urlMapString
		const keys = Object.keys(params)
		keys.forEach((key) => {
			const paramValue = params[key]
			if (!paramValue) {
				throw new Error(`urlConstructionError @ ${urlMapString}, ${key}: ${paramValue}`)
			}
			const regex = new RegExp(`[:]${key}`, 'gi')
			url = url.replace(regex, paramValue)
		})
		return url
	} catch (error) {
		return '/404'
	}
}
