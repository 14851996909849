import {
	apolloClient,
	DISMISS_BULLETIN_MUTATION,
	GET_AUTHORIZED_WORKER,
	GET_GENERAL_RANKED_JOBS_QUERY,
	GET_HOT_JOBS_QUERY,
	GET_JOB_DISCOVERS_QUERY,
	GET_POPULAR_CITIES_QUERY,
	GET_RECOMMENDED_JOBS_QUERY,
	GET_CREDENTAIL_BE_SUBMITTION_QUERY,
} from '~/common/apollo'
import { action, computed, observable, store } from '~/common/mobx.decorator'
import { authStore, notifyStore, routerStore } from '~/stores'
import { isEmpty } from 'lodash'
import { EXTEND_JOB_APPLICANT_MUTATION } from '~/common/apollo/mutations/job-applicant.mutation'
import { careFindJobStore } from '~/features/care-find-job/care-find-job.store'
import { PATHS } from '~/common/constants'
import { guidanceStore } from '~/components/guidance-components/guidance.store'

@store()
class CareHomeStore {
	@observable numCityCall = 0
	@observable numJobCall = 0

	@observable citiesMatches = []
	@observable workAuthorized = {}
	@observable hotJobs = []
	@observable recommendedJobs = []
	@observable popularCities = []
	@observable bulletins = []
	@observable discover = {}
	@observable discovers = {}
	@observable phoneAuthorized = null
	@observable phoneVerifiedAt = null
	@observable workerInfo = null
	@observable settings = {}
	@observable discoverLastSearch = {}
	@observable totalCountLastSearch = 0
	@observable similarJobFilter = {}
	@observable lastSearchTitle = ''
	@observable credentialBeSubmittion = []

	@computed
	get citiesMatchesItems() {
		return this.citiesMatches
	}

	@action
	setSimilarJobFilter = (filter) => {
		this.similarJobFilter = careFindJobStore.transferDataParams(filter)
	}

	@computed
	get hotJobsItems() {
		return this.hotJobs
	}

	@computed
	get recommendedJobItems() {
		return this.recommendedJobs
	}

	@computed
	get popularCitiesItems() {
		return this.popularCities
	}

	@action
	fetchCitiesMatches = async () => {
		try {
			await apolloClient.query({
				query: GET_GENERAL_RANKED_JOBS_QUERY,
				variables: {
					first: 2,
					filter: {
						hotJob: true,
					},
				},
			})
		} catch (error) {
			console.error(error)
		}
	}

	@action
	setHotJob = (jobs) => {
		this.hotJobs = jobs
	}
	@action
	setSuggestedJob = (jobs) => {
		this.suggestedJobs = jobs
	}

	@action
	fetchHotJobs = async () => {
		try {
			const response = await apolloClient.query({
				query: GET_HOT_JOBS_QUERY,
				variables: {
					filter: { hotJob: true },
					sortBy: {
						field: 'SCORE',
						order: 'desc',
					},
					first: 3,
					sortBy: {
						field: 'SCORE',
						order: 'desc',
					},
				},
			})
			this.hotJobs = response.data.searchJobs?.nodes
		} catch (error) {
			console.error(error)
		}
	}

	@action
	fetchRecommendedJobs = async () => {
		const response = await apolloClient.query({
			query: GET_RECOMMENDED_JOBS_QUERY,
			variables: {
				first: 3,
				sortBy: {
					field: 'SCORE',
					order: 'desc',
				},
			},
		})
		this.recommendedJobs = response?.data?.suggestionJobs?.nodes
	}

	@action
	fetchPopularCities = async () => {
		try {
			const response = await apolloClient.query({
				query: GET_POPULAR_CITIES_QUERY,
			})
			this.popularCities = response?.data?.popularCities
		} catch (error) {
			console.error(error)
		}
	}

	@action
	fetchJobDiscover = async () => {}

	@action
	fetchGuidances = async () => {
		this.bulletins = []
	}

	@action
	dismissBulletinForAnonymous = async (bulletinId) => {
		try {
			const deleteBulletinsAnoParsed = JSON.parse(window.localStorage.getItem('deleteBulletinsAno')) || []
			let deleteBulletinsAno = isEmpty(deleteBulletinsAnoParsed) ? [] : deleteBulletinsAnoParsed
			deleteBulletinsAno.push(bulletinId)
			window.localStorage.setItem('deleteBulletinsAno', JSON.stringify(deleteBulletinsAno))
			this.fetchGuidances()
			notifyStore.success('$MESSAGES.SUCCESSFUL')
		} catch (error) {
			notifyStore.error(error.message)
		}
	}

	@action
	dissmisBulletin = async (bulletinId) => {
		if (authStore.isAnonymous) {
			this.dismissBulletinForAnonymous(bulletinId)
			return
		}

		try {
			await apolloClient.mutate({
				mutation: DISMISS_BULLETIN_MUTATION,
				variables: { bulletinId },
			})
			notifyStore.success('$MESSAGES.SUCCESSFUL')
			this.fetchGuidances()
		} catch (error) {
			notifyStore.error(error.message)
		}
	}

	@action
	fetchDiscovers = async () => {
		try {
			const { data } = await apolloClient.query({
				query: GET_JOB_DISCOVERS_QUERY,
			})
			this.discovers = data.discovers
			if (data?.discovers?.lastSearch?.type === 'posted_today') {
				this.lastSearchTitle = 'Posted Today'
			} else {
				this.lastSearchTitle = data?.discovers.lastSearch.count === 1 ? 'Last search' : 'Last searches'
			}

			// this.discoverLastSearch = data?.discovers.lastSearch
			this.totalCountLastSearch = data?.discovers.lastSearch.count
		} catch (error) {
			console.error(error)
		}
	}

	@action
	fetchWorkerAuthorized = async () => {
		try {
			const { data } = await apolloClient.query({
				query: GET_AUTHORIZED_WORKER,
				variables: {
					workerId: authStore.id,
				},
			})
			this.workerInfo = data?.worker
			this.phoneAuthorized = data?.worker?.phone
			this.phoneVerifiedAt = data?.worker?.phoneVerifiedAt
		} catch (error) {
			console.error(error)
		}
	}

	@action
	fetchCredentialBeSubmittion = async () => {
		try {
			const { data } = await apolloClient.query({
				query: GET_CREDENTAIL_BE_SUBMITTION_QUERY,
			})
			this.credentialBeSubmittion = data?.workerCredentials
		} catch (error) {
			console.error(error)
		}
	}

	@action
	onExtendPlaceJob = async (values) => {
		try {
			const { note, timeOffEndDate, timeOffStartDate, jobApplicantId } = values

			await apolloClient.mutate({
				mutation: EXTEND_JOB_APPLICANT_MUTATION,
				variables: { workerAssignmentId: jobApplicantId, note, timeOffEndDate, timeOffStartDate },
			})

			notifyStore.success('$MESSAGES.SUCCESSFUL')
			this.fetchGuidances()
		} catch (error) {
			notifyStore.error(error.message)
		}
	}

	openRefineMatchesDialog = () => {
		return notifyStore.open(
			'In just a few minutes tell us more about you and get the best job matches for your needs.',
			{
				title: 'Refine your matches',
				showDialog: true,
				keepOriginal: true,
				okText: 'Complete now',
				cancelText: 'Do it later',
				showButtonClose: false,
				onOk: (key) => {
					notifyStore.closeDialog(key)
					routerStore.goPage(PATHS.care.activation)
				},
				onCancel: (key) => {
					notifyStore.closeDialog(key)
				},
			}
		)
	}

	openBeReadyDialog = () => {
		return notifyStore.open('Complete your profile and get your applications sent to the front of the line.', {
			title: 'Be Submission Ready',
			showDialog: true,
			keepOriginal: true,
			okText: 'Complete now',
			cancelText: 'Do it later',
			onOk: (key) => {
				notifyStore.closeDialog(key)
				guidanceStore.setShowDialog(true)
			},
			onCancel: (key) => {
				notifyStore.closeDialog(key)
			},
		})
	}
}

export const careHomeStore = new CareHomeStore()
