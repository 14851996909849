import React from 'react'
import { SvgIcon, Typography as MuiTypography } from '@material-ui/core'
import { typographyStyle } from './typography.style'

export const Typography = ({ icon: Icon, children, ...props }) => (
	<MuiTypography {...props} css={typographyStyle}>
		{Icon && (
			<SvgIcon>
				<Icon />
			</SvgIcon>
		)}
		{children}
	</MuiTypography>
)

export const SingleLineTypography = ({ icon: Icon, children, style, ...props }) => {
	return (
		<Typography
			{...props}
			icon={Icon}
			style={{
				...style,
				display: 'inline-block',
				overflow: 'hidden',
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis',
				width: '100%',
			}}
		>
			{children}
		</Typography>
	)
}

export const MultipleLinesTypography = ({ maxLines, icon: Icon, children, ...props }) => {
	return (
		<Typography
			{...props}
			icon={Icon}
			style={{
				display: '-webkit-box',
				WebkitLineClamp: maxLines,
				WebkitBoxOrient: 'vertical',
				overflow: 'hidden',
				width: '100%',
			}}
		>
			{children}
		</Typography>
	)
}
